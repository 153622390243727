import React from 'react';
import PropTypes from 'prop-types';
import CONSTANTS from '../../lib/constants.js';
import { useParams, Link } from 'react-router-dom';
import { Switch } from '@mui/material';
import SortableList, { SortableItem } from 'react-easy-sort';
import { messageBox, AddButtonFAB, Toolbar, ToolbarIcon, InfiniteScroll } from 'ui-core';
import { BasePage } from 'app-center-common';
import { ExperiencePicker } from '../entity-picker';
import ExpFeedQueryBuilder from './exp-feed-query-builder/exp-feed-query-builder.jsx';

import { api, logger } from 'client-services';
import { arrayMoveImmutable, spliceImmutable } from '../../lib/immutable-utils.js';
import clsx from 'clsx';

import './experiences-feed-page.css';

const MAX_EXPS_PER_FEED = 200;

class ExperienceFeedPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			hasMore: true,
			experienceFeed: null,
			experiences: [],
			manuallyManaged: true,
			showExpPicker: false,
			rowDisplay: false,
			publicOnly: false,
			sortDesc: true,
			showQueryBuilder: false
		};

		this.page = 0;
		this.loading = false;

		this.loadMoreExperiences = this.loadMoreExperiences.bind(this);
		this.onSortEnd = this.onSortEnd.bind(this);
		this.onAddExperiences = this.onAddExperiences.bind(this);
		this.onExpFeedQueryUpdate = this.onExpFeedQueryUpdate.bind(this);
		this.onExpFeedQueryDelete = this.onExpFeedQueryDelete.bind(this);
		this.onShowQueryBuilder = this.onShowQueryBuilder.bind(this);
		this.onTogglePublic = this.onTogglePublic.bind(this);
		this.onToggleSort = this.onToggleSort.bind(this); 	
	}

	// #region Methods

	reload() {
		this.page = 0;
		this.setState({
			hasMore: true,
			experienceFeed: null,
			experiences: []
		}, () => this.load());
	}

	async load() {
		let mb;
		try {
			let id = this.props.params.id;

			// We fetch all the experiences of this list
			mb = messageBox('Loading...', '', [], true);
			logger.trace('Fetching experience feed:', id);
			let res = await api.experience.getExperienceFeed({ id }).send();
			if (!res?.experienceFeed) {
				throw new Error('Invalid response');
			}

			let experienceFeed = res.experienceFeed;
			let manuallyManaged = !experienceFeed.query;

			this.setState({ 
				experienceFeed, 
				manuallyManaged, 
				sortDesc: experienceFeed.sortDesc ?? true 
			}, () => this.loadMoreExperiences());
			mb.close();
		} catch (ex) {
			mb.setTitle('Error', false);
			mb.setBody(ex.message);
		}
	}

	async loadMoreExperiences() {
		// sometimes infinite scroll can call this method before we finished previous request.
		if (this.loading) { return; }
		this.loading = true;

		let { experienceFeed, experiences, manuallyManaged, publicOnly } = this.state;

		// For manually managed feed we must load all experiences as when saving we must
		// save all the experiences as an embedded array.
		// we also load all experiences and do the "public only" filtering client side
		publicOnly = !manuallyManaged && publicOnly;
		let count = 50;
		let hasMore = true;

		let mb = messageBox('Loading...', '', [], true);
		try {
			while (hasMore) {
				if (manuallyManaged && experiences.length > MAX_EXPS_PER_FEED) {
					throw new Error('Exp feed has too many experiences');
				}

				logger.trace('Fetching experiences, page:', this.page);
				let res = await api.experience.getAppExperiences({ 
					id: experienceFeed.id, 
					publicOnly, 
					page: this.page, 
					count
				}).send();
				if (!Array.isArray(res?.experiences)) {
					throw new Error('Invalid response fetch app experiences');
				}

				let exps = res.experiences.map(e => ({id: e.id, status: e.status, thumbnail: e.thumbnail, name: e.name, isLive: e.isLive}));
				hasMore = exps.length >= count;

				experiences.push(...exps);
				mb.setBody(`Loaded ${experiences.length} experiences`);
				++this.page;

				// on dynamic feed we load only 1 page at a time
				if (!manuallyManaged) {
					break;
				}
			}
		} catch (ex) {
			mb.setTitle('Error', false);
			mb.setBody(ex.message);
		}

		this.setState({ experiences, hasMore });
		mb.close();
		this.loading = false;
	}

	removeExp(id) {
		var idx = this.state.experiences.findIndex(e => e.id === id);
		if (idx < 0) { return; }
		var experiences = spliceImmutable(this.state.experiences, idx, 1);
		this.setState({ experiences });
	}

	// #endregion Methods

	// #region EventHandlers

	onSortEnd(oldIdx, newIdx) {
		var experiences = arrayMoveImmutable(this.state.experiences, oldIdx, newIdx);
		this.setState({ experiences });
	}

	async onSave(expFeedQuery) {
		var experiences = this.state.experiences.map(e => e.id);
		var mb = messageBox('Updating experiences...', null, null, true);
		try {
			let params = { id: this.state.experienceFeed.id, experiences };

			// If we got expFeedQuery we must make sure experiences is empty array.
			// expFeedQuery can be also "null" (deleting existing query)
			if (typeof expFeedQuery !== 'undefined') {
				params.query = expFeedQuery;
				params.sortDesc = this.state.sortDesc;
				params.experiences = [];
			}

			await api.experience.updateExperienceFeed(params).send();
			mb.close();
			this.reload();
		} catch (ex) {
			mb.setTitle('Error', false);
			mb.setBody(ex.message);
		}
	}

	onAddExperiences(exps) {
		this.setState({ showExpPicker: false });
		if (!Array.isArray(exps)) { return; }
		var newExps = exps.filter(e => !this.state.experiences.some(se => se.id === e.id));
		let total = newExps.length + this.state.experiences.length;
		if (total > MAX_EXPS_PER_FEED) {
			messageBox('Error', `Maximum experiences allowed is ${MAX_EXPS_PER_FEED}, found ${total} experiences.`);
			return;
		}

		this.setState({ experiences: [...newExps, ...this.state.experiences] });
	}

	onExpFeedQueryUpdate(query) {
		this.setState({ showQueryBuilder: false }, () => query && this.onSave(query));
	}

	async onExpFeedQueryDelete() {
		let res = await messageBox(
			'Are you sure?',
			'Deleting the query will convert the experience feed to be manually managed, continue with deletion?',
			[messageBox.Buttons.Cancel, messageBox.Buttons.Yes]
		).promise;

		if (res !== messageBox.Buttons.Yes) { return; }

		this.setState({ showQueryBuilder: false }, () => this.onSave(null));
	}

	async onShowQueryBuilder() {
		let { experiences, manuallyManaged } = this.state;

		// Adding query to a feed will delete all embedded experiences
		if (manuallyManaged && experiences.length > 0) {
			let res = await messageBox(
				'Static Experience Feed',
				'Adding a query will convert the experience feed to be dynamic and all current experiences will be removed, continue?',
				[messageBox.Buttons.Cancel, messageBox.Buttons.Yes]
			).promise;

			if (res !== messageBox.Buttons.Yes) { return; }
		}

		this.setState({ showQueryBuilder: true });
	}

	onTogglePublic(allExps) {
		let { manuallyManaged } = this.state;
		this.setState({ publicOnly: !allExps }, () => !manuallyManaged && this.reload());
	}

	async onToggleSort(isDesc) {
		try {
			let params = { 
				id: this.state.experienceFeed.id,
				sortDesc: isDesc
			};
			
			let mb = messageBox('Updating sort order...', null, null, true);
			await api.experience.updateExperienceFeed(params).send();
			mb.close();
			
			// Update local state and reload
			this.setState({ sortDesc: isDesc }, () => !this.state.manuallyManaged && this.reload());
		} catch (ex) {
			messageBox('Error', 'Failed to update sort order: ' + ex.message);
		}
	}

	// #endregion EventHandlers

	// #region Lifecycle

	componentDidMount() {
		this.reload();
	}

	componentDidUpdate(prevProps) {
		var id = this.props.params.id;
		var pid = prevProps.params.id;
		if (id === pid) {
			return;
		}

		this.reload();
	}

	// #endregion Lifecycle

	// #region Render

	renderToolbar() {
		let { rowDisplay, publicOnly, experienceFeed, manuallyManaged, sortDesc } = this.state;
		return (
			<Toolbar>
				<ToolbarIcon onClick={() => this.onSave()} size="medium" name="save" disabled={!!experienceFeed?.query}>Save</ToolbarIcon>
				<ToolbarIcon onClick={() => this.setState({rowDisplay: !rowDisplay})} size="medium" name={rowDisplay ? 'grid_view' : 'view_module'}>{rowDisplay ? 'Grid' : 'Row'}</ToolbarIcon>
				<ToolbarIcon onClick={this.onShowQueryBuilder} size="medium" name="filter_alt">Query</ToolbarIcon>
				{!manuallyManaged && (
					<SortOrderToggle 
						isDesc={sortDesc} 
						onChange={this.onToggleSort}
						style={{marginLeft: 'auto', marginRight: '20px'}} 
					/>
				)}
				<PublicToggle allExps={!publicOnly} onChange={this.onTogglePublic} style={{marginLeft: manuallyManaged ? 'auto' : 'initial'}} />
			</Toolbar>
		);
	}

	renderExperiences() {
		let { experiences, manuallyManaged, publicOnly, hasMore } = this.state;
		if (manuallyManaged) {
			experiences = experiences.filter(exp => !publicOnly || exp.status !== CONSTANTS.ExperienceStatus.Draft);
		}

		return (
			<div className="grid-wrap">
				<InfiniteScroll scrollOn=".grid-wrap" hasMore={hasMore} loadMore={this.loadMoreExperiences}>
					<SortableList
						allowDrag={manuallyManaged}
						onSortEnd={this.onSortEnd}
						className="grid"
						draggedItemClassName="exp-drag"
					>
						{experiences.map(exp => (
							<ExperienceItem
								key={exp.id}
								experience={exp}
								onDelete={() => this.removeExp(exp.id)}
								manuallyManaged={manuallyManaged}
							/>
						))}
					</SortableList>
				</InfiniteScroll>
			</div>
		);
	}

	render() {
		if (!this.state.experienceFeed) { return 'Loading...'; }

		let { rowDisplay, experienceFeed, experiences, manuallyManaged, showExpPicker, showQueryBuilder } = this.state;

		var name = experienceFeed.name || '';
		var count = manuallyManaged ? `${experiences.length} experiences` : `Dynamic Feed - ${experiences.length} exps loaded`;

		return (
			<BasePage
				className={clsx('exps-list', { row: rowDisplay })}
				title={`Experiences List - ${name}`}
				subTitle={`(${count})`}
				toolbar={this.renderToolbar()}
				toolbarOpen={true}
			>
				{this.renderExperiences()}
				<ExperiencePicker
					showPicker={showExpPicker}
					pickerOnly={true}
					openPickerOnSelected={false}
					onCommit={this.onAddExperiences}
				/>
				<ExpFeedQueryBuilder
					open={showQueryBuilder}
					initialQuery={experienceFeed.query || null}
					onClose={this.onExpFeedQueryUpdate}
					onDeleteQuery={this.onExpFeedQueryDelete}
				/>
				{
					manuallyManaged &&
					<AddButtonFAB onClick={() => { this.setState({ showExpPicker: true }); }} />
				}
			</BasePage>
		);
	}

	// #endregion Render
}

ExperienceFeedPage.propTypes = {
	params: PropTypes.object,
};

export default function WrappedExperienceFeedPage(props) {
	let params = useParams();
	return <ExperienceFeedPage params={params} {...props} />;
}

function ExperienceItem(props) {
    let { experience, onDelete, manuallyManaged } = props;

    let draft = experience.status === CONSTANTS.ExperienceStatus.Draft;
    let status = '';
    switch (experience.status) {
        case CONSTANTS.ExperienceStatus.Draft:
            status = 'Draft';
            break;
        case CONSTANTS.ExperienceStatus.RC:
            status = 'RC';
            break;
    }

    if (experience.isLive) {
        status += ' Live';
    }

    return (
        <SortableItem key={experience.id}>
            <div className={clsx('exp', { draft }) }>
                {status && <div className="exp-title">{status}</div>}
                <img src={experience.thumbnail} />
                <div className="exp-tools">
                    <span><Link target="_blank" to={`/experience/${experience.id}`}>{experience.name}</Link></span>
                    {manuallyManaged && (
                        <ToolbarIcon
                            name="delete"
                            size="small"
                            color="secondary"
                            onClick={onDelete}
                        />
                    )}
                </div>
            </div>
        </SortableItem>
    );
}

ExperienceItem.propTypes = {
    experience: PropTypes.object,
    onDelete: PropTypes.func,
    manuallyManaged: PropTypes.bool
};

function PublicToggle(props) {
	let { style, allExps, onChange } = props;

	return (
		<div style={style}>
			<span>Public Only</span>
			<Switch checked={allExps} onChange={(e, checked) => onChange?.(checked)} />
			<span>All Exps</span>
		</div>
	);
}

PublicToggle.propTypes = {
	style: PropTypes.object,
	allExps: PropTypes.bool,
	onChange: PropTypes.func
};

function SortOrderToggle(props) {
    let { style, isDesc, onChange } = props;

    return (
        <div style={style}>
            <span>Oldest First</span>
            <Switch checked={isDesc} onChange={(e, checked) => onChange?.(checked)} />
            <span>Newest First</span>
        </div>
    );
}

SortOrderToggle.propTypes = {
    style: PropTypes.object,
    isDesc: PropTypes.bool,
    onChange: PropTypes.func
};